import verifyAdmin from './png/verifyAdmin.png';
import addUser from './png/addUser.png';
import addUserWhite from './png/addUserWhite.png';
import mobileNumber from './png/mobileNumber.png';
import mail from './png/mail.png';
import kycSuccess from './png/kycSuccess.png';
import calendar from './png/calendar.png';
import hamburger from './png/hamburger.png';

import all from './png/dashboard/all.png';
import pending from './png/dashboard/pending.png';
import reActive from './png/dashboard/reActive.png';
import rejected from './png/dashboard/rejected.png';
import resubmitted from './png/dashboard/resubmitted.png';
import verified from './png/dashboard/verified.png';

import shareScreen from './png/kyc/shareScreen.png';
import uploadUser from './png/kyc/uploadUser.png';
import clientSign from './png/kyc/clientSign.png';
import doc from './png/kyc/doc.png';
import search from './png/kyc/search.png';

import logo from './svg/logo.svg';
import bigul from './svg/bigul.svg';
import bonabza from './svg/bonabza.svg';
import Bonanza from './svg/Bonanza.svg';
import kyc from './svg/kyc.svg';
import user from './svg/user.svg';
import rightArrow from './svg/rightArrow.svg';
import defaultUser from './svg/defaultUser.svg';
import notification from './svg/notification.svg';
import widgetApplication from './svg/application.svg';
import graph1 from './svg/graph.svg';
import graph2 from './svg/graph2.svg';
import graph3 from './svg/graph3.svg';
import verify from './svg/verify.svg';
import notverify from './svg/notverify.svg';
import backIcon from './svg/back.svg';
import continueIcon from './svg/continue.svg';
import profileUser from './svg/profileUser.svg';
import signature from './svg/signature.svg';
import docView from './svg/docView.svg';
import rightField from './svg/rightField.svg';
import wrongField from './svg/wrongField.svg';
import dropdownIcon from './svg/dropdownIcon.svg';
import invalidInput from './svg/invalidInput.svg';

import loginScreenImg from './svg/login/loginScreenImg.svg';
import kycAssist from './svg/login/kycAssist.svg';
import password from './svg/login/password.svg';
import usermail from './svg/login/usermail.svg';
import showPassword from './svg/login/showPassword.svg';
import hidePassword from './svg/login/hidePassword.svg';
import loginEnter from './svg/login/loginEnter.svg';

import eye from './svg/widget/eye.svg';

import todayCalendar from './svg/dashboard/todayCalendar.svg';
import yesterdayCalendar from './svg/dashboard/yesterdayCalendar.svg';

import dashboard from './svg/sidebar/dashboard.svg';
import selectedDashboard from './svg/sidebar/selectedDashboard.svg';
import startKyc from './svg/sidebar/startKyc.svg';
import selectedStartKyc from './svg/sidebar/selectedStartKyc.svg';
import eSign from './svg/sidebar/eSign.svg';
import selectedESign from './svg/sidebar/selectedESign.svg';
import application from './svg/sidebar/application.svg';
import selectedApplication from './svg/sidebar/selectedApplication.svg';
import accountList from './svg/sidebar/accountList.svg';
import selectedAccountList from './svg/sidebar/selectedAccountList.svg';
import report from './svg/sidebar/report.svg';
import selectedReport from './svg/sidebar/selectedReport.svg';
import master from './svg/sidebar/master.svg';
import selectedMaster from './svg/sidebar/selectedMaster.svg';
import download from './svg/sidebar/download.svg';
import selectedDownload from './svg/sidebar/selectedDownload.svg';
import linkAadhar from './svg/sidebar/linkAadhar.svg';
import selectedLinkAadhar from './svg/sidebar/selectedLinkAadhar.svg';
import setting from './svg/sidebar/setting.svg';
import selectedSetting from './svg/sidebar/selectedSetting.svg';
import logout from './svg/sidebar/logout.svg';
import selectedLogout from './svg/sidebar/selectedLogout.svg';
import userList from './svg/sidebar/userList.svg';
import selectedUserList from './svg/sidebar/selectedUserList.svg';

import correctTick from './svg/review/correctTick.svg';
import wrongTick from './svg/review/wrongTick.svg';
import undo from './svg/review/undo.svg';

import plus from './svg/kyc/plus.svg';
import kycMessage from './svg/kyc/kycMessage.svg';
import send from './svg/kyc/send.svg';
import panCard from './svg/kyc/panCard.svg';
import dateOfBirth from './svg/kyc/dateOfBirth.svg';
import weddingRing from './svg/kyc/weddingRing.svg';
import idCard1 from './svg/kyc/idCard1.svg';
import gender from './svg/kyc/gender.svg';
import city from './svg/kyc/city.svg';
import flag from './svg/kyc/flag.svg';
import occupation from './svg/kyc/occupation.svg';
import income from './svg/kyc/income.svg';
import education from './svg/kyc/education.svg';
import trading from './svg/kyc/trading.svg';
import passbook from './svg/kyc/passbook.svg';
import selfie from './svg/kyc/selfie.svg';
import signatureInput from './svg/kyc/signature.svg';
import upload from './svg/kyc/upload.svg';
import uploadWhite from './svg/kyc/uploadWhite.svg';
import closeIcon from './svg/close.svg'
import infoIcon from './svg/kyc/infoIcon.svg'
import greencheck from './svg/kyc/green-check.svg'
import uploadIcon from './svg/kyc/uploadIcon.svg'
import white_notificationIcon from './svg/notificationIcon.svg'
import check from './svg/notification/check.svg'
import info from './svg/notification/info.svg'
import expandMore from './svg/notification/expand.svg'
import slider from './svg/Slider.svg'
import checkbox from './svg/checkbox.svg'
import square from './svg/square.svg'
import sortIcon from './svg/sortIcon.svg'
import camera from './svg/camera.svg'
import cameraOutline from './svg/cameraOutline.svg'
import loader_white from './gif/Dark Theme/Bigul-Logo_Loader-Animation_White.gif'
import leftpart1 from './png/dkyc/leftpart.png'
import leftpart2 from './png/dkyc/leftpart2.png'
import leftpart3 from './png/dkyc/leftpart3.png'
import leftpart4 from './png/dkyc/leftpart4.png'
import deleteIcon from './svg/dkyc/delete.svg'
import viewIcon from './svg/dkyc/viewIcon.svg'
import filter from './svg/filter.svg'


export const pngAssets = {
	bigul,
	dashboard: {
		all,
		pending,
		reActive,
		rejected,
		resubmitted,
		verified
	},
	kyc: {
		shareScreen,
		mobileNumber,
		mail,
		kycSuccess,
		uploadUser,
		clientSign,
		doc,
		search
	},
	reactTable: {
		addUser,
		addUserWhite,
		calendar
	},
	admin: {
		verifyAdmin
	},
	dkyc: {
		leftpart1,
		leftpart2,
		leftpart3,
		leftpart4,
	}
};

export const svgAssets = {
	logo,
	bigul,
	bonabza,
	Bonanza,
	dropdownIcon,
	user,
	closeIcon,
	slider,
	checkbox,
	square,
	sortIcon,
	camera,
	cameraOutline,
	filter,
	login: {
		loginScreenImg,
		kycAssist,
		password,
		usermail,
		showPassword,
		hidePassword,
		loginEnter
	},
	navbar: {
		defaultUser,
		notification,
		hamburger,
		white_notificationIcon
	},
	sidebar: {
		dashboard,
		selectedDashboard,
		startKyc,
		selectedStartKyc,
		eSign,
		selectedESign,
		application,
		selectedApplication,
		accountList,
		selectedAccountList,
		report,
		selectedReport,
		master,
		selectedMaster,
		download,
		selectedDownload,
		linkAadhar,
		selectedLinkAadhar,
		setting,
		selectedSetting,
		logout,
		selectedLogout,
		userList,
		selectedUserList
	},
	widget: {
		eye
	},
	dashboard: {
		kyc,
		rightArrow,
		widgetApplication,
		graph1,
		graph2,
		graph3,
		calendar,
		todayCalendar,
		yesterdayCalendar,
	},
	kyc: {
		plus,
		send,
		verify,
		notverify,
		backIcon,
		continueIcon,
		profileUser,
		signature,
		docView,
		invalidInput,
		kycMessage,
		panCard,
		dateOfBirth,
		weddingRing,
		idCard1,
		gender,
		city,
		flag,
		occupation,
		income,
		education,
		trading,
		passbook,
		selfie,
		signatureInput,
		upload,
		uploadWhite,
		infoIcon,
		greencheck,
		uploadIcon
	},
	review: {
		correctTick,
		wrongTick,
		undo
	},
	verifyApplication: {
		rightField,
		wrongField
	},
	notificaton: {
		check,
		info,
		expandMore
	},
	dkyc: {
		viewIcon,
		deleteIcon,
	}
};

export const gif = {
	loader_white
}