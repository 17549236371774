import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { svgAssets } from '../../assets/asset';
import { BaseLogin } from '../../components/login/BaseLogin';
import { changeLoginErrorAction, changeLoginInputAction, clearLoginInputAction, loginUserAction, showPasswordAction } from '../../redux/actions/auth.action';
import { validatePassword, validateUserId } from '../../utils/verifyInput';
import { store } from '../../redux/store';
import { stopLoaderAction } from '../../redux/actions/app.action';

export const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(state => state?.user || {});
	const [userIdVal, setUserId] = useState('');
	const [password, setPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(false);

	useEffect(() => {
		store.dispatch(stopLoaderAction());
		const storedUserId = localStorage.getItem('userId');
		const storedPassword = localStorage.getItem('password');
		const storedRememberMe = localStorage.getItem('rememberMe');


		if (storedRememberMe === 'true' && storedUserId && storedPassword) {
			setUserId(storedUserId);
			setPassword(storedPassword);
			setRememberMe(true);
		}
	}, []);

	

	const handleRememberMeChange = () => {
		setRememberMe(!rememberMe);
	};

	const handleInput = ({ type, value }) => {
		if (type === 'userId') {
			setUserId(value)
		} else if (type === 'password') {
			setPassword(value)
		}
		dispatch(changeLoginInputAction({ type, value }));
	};

	const handleInputError = ({ type, isError, errorText }) => {
		dispatch(changeLoginErrorAction({ type, isError, errorText }));
	};

	const loginApiCallback = ({ status, success, message}) => {
		if (status === 200 && success) {
			navigate('/');
		} else {
			alert(message || 'invalid credentials!');
		}
	};

	const handleLogin = () => {
		const userId = validateUserId(user?.userId?.value || '');
		const isValidPassword = validatePassword(user?.password?.value || '');

		if (rememberMe) {
			localStorage.setItem('userId', userIdVal);
			localStorage.setItem('password', password);
			localStorage.setItem('rememberMe', true);
		} else {
			localStorage.removeItem('userId');
			localStorage.removeItem('password');
			localStorage.removeItem('rememberMe');
		}
		if (userIdVal && password) {
			dispatch(loginUserAction({
				UserName: user.userId.value || userIdVal,
				Password: user.password.value || password
			}, loginApiCallback));
		} else {
			if (!userId) {
				handleInputError({ type: 'userId', isError: true, errorText: 'Please enter a valid user Id' });
			} if (!isValidPassword) {
				handleInputError({ type: 'password', isError: true, errorText: 'Password should have atleast 6 characters!' });
			} else {
				dispatch(loginUserAction({
					UserName: user.userId.value || userIdVal,
					Password: user.password.value || password
				}, loginApiCallback));
			}
		}

	};

	useEffect(() => {
		dispatch(clearLoginInputAction());
	}, []);

	return (
		<BaseLogin>
			<div className="w-full max-h-max mt-[76px] flex flex-col justify-center px-2" style={{maxWidth: '400px'}}>
				
				<div className="mb-6">
				    {/* <div className="w-100 mb-6 text-center"> 
                        <img src="http://localhost:3000/static/media/logo.428c3628d293ce81834bcaa296bb33a9.svg" alt="logo" class="mx-auto d-block" width="120px" />
				    </div> */}
					<h6 className="mb-7 text-black text-2xl capitalize font-semibold font-poppinsSemiBold text-center">login</h6>
					<div className="flex flex-col mb-8">
						{/* <label className="mb-3 flex items-center leading-6 font-medium font-poppinsMedium">
							<img
								className="max-h-[22px] mr-1.5 object-contain"
								alt="input_icon"
								src={svgAssets.user}
							/>
							<span className="mr-1">User Id</span>
							<span className="text-[#FF4343]">*</span>
						</label> */}
						<input
							className="h-[56px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[10px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none rounded-[12px]"
							placeholder="User ID / Email Address"
							value={user?.userId?.value || userIdVal || ''}
							onChange={(e) => handleInput({ type: 'userId', value: e.target.value })}
							autoFocus={true}
						/>
						{
							user?.userId?.isError && (
								<span className="mt-2 text-[12px] text-red-400 font-medium font-poppinsMedium">
									{user.userId.errorText}
								</span>)
						}
					</div>
					<div className="flex flex-col mb-6">
						{/* <span className="mb-3 flex items-center leading-6 font-medium font-poppinsMedium">
							<img
								className="max-h-[22px] mr-1.5 object-contain"
								alt="input_icon"
								src={svgAssets.login.password}
							/>
							<span className="mr-1">Password</span>
							<span className="text-[#FF4343]">*</span>
						</span> */}
						<div className="relative">
							<input
								className="w-full h-[56px] px-4 text-[#353535] border border-solid border-[#DFDFDF] rounded-[12px] shadow-[0px_2px_10px_rgba(201,201,201,0.25)] font-poppinsRegular leading-6 focus:outline-none"
								type={!user?.password?.showPassword ? 'password' : 'text'}
								value={user?.password?.value || password || ""}
								onChange={(e) => handleInput({ type: 'password', value: e.target.value })}
								placeholder="Password"
								required
							/>
							<img
								className="cursor-pointer absolute right-3 top-0 bottom-0 mt-auto mb-auto"
								width={24} alt="hide_password"
								src={user?.password?.showPassword ? svgAssets.login.showPassword : svgAssets.login.hidePassword}
								onClick={() => dispatch(showPasswordAction({ type: 'password' }))}
							/>
						</div>
						{
							user?.password?.isError && (
								<span className=" mt-2 text-[12px] text-red-400 font-medium font-poppinsMedium">
									{user.password.errorText}
								</span>)
						}
					</div>
					<div>
						<div className="text-[#666666] leading-tight flex items-center justify-between font-poppinsRegular">
							<div>
								<input
									className="form-checkbox mt-1"
									type="checkbox"
									checked={rememberMe}
									onChange={handleRememberMeChange}
								/>
								<label className="ml-2">Remember me</label>
							</div>

							<Link
								to="/forgot-password"
							>
								<span className="hover:text-[#5367FC] hover:underline">
									Forgot Password?
								</span>
							</Link>
						</div>
					</div>
				</div>
				<div className="flex justify-center">
					<button
						className="w-100 w-full px-9 py-3 flex items-center justify-center rounded-[10px] text-white bg-[#5367FC] active:bg-[#3d54fc] transition duration-150 ease-in-out rounded-[12px]"
						onClick={() => handleLogin()}
					>
						{/* <img className="mr-2.5" alt="login_screen_enter_img" src={svgAssets.login.loginEnter}></img> */}
						<span className="font-poppinsRegular">Login</span>
					</button>
				</div>
			</div>
		</BaseLogin>
	);
};


// import React, { useState } from 'react';

// export const Login = () => {
//   const [step, setStep] = useState(1);
//   const [mobile, setMobile] = useState('');
//   const [error, setError] = useState(false);
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [lastname, setLastname] = useState('');
//   const [graduation, setGraduation] = useState('');
//   const [users, setUsers] = useState([]);

//   const handleMobileVerification = () => {
//     setStep(2);
//   };

//   const handleEmailVerification = () => {
//     setStep(3);
//   };

//   const handleGeneralInfoSubmission = () => {
//     const user = { mobile, email, name, lastname, graduation };
//     const usersData = [...users, user];
//     setUsers(usersData);
//     localStorage.setItem('usersData', JSON.stringify(usersData));
//     setStep(1);
//     setMobile('');
//     setEmail('');
//     setName('');
//     setLastname('');
//     setGraduation('');
//   };

//   const handleStep1Next = () => {
//     const mobileExists = users.some((user) => user.mobile === mobile);
//     setError(mobileExists);

//     if (!mobileExists && mobile.trim() !== '') {
//       handleMobileVerification();
//     } else {
//       // Handle mobile validation error
//     }
//   };

//   const handleStep2Next = () => {
//     const emailExists = users.some((user) => user.email === email);
//     setError(emailExists);

//     if (!emailExists && email.trim() !== '') {
//       handleEmailVerification();
//     } else {
//       // Handle email validation error
//     }
//   };

//   const handleMobileChange = (e) => {
//     setMobile(e.target.value);
//     setError(false); // Reset error when the user changes the mobile input
//   };

//   return (
//     <div>
//       {step === 1 && (
//         <div>
//           <h2>Step 1: Mobile Verification</h2>
//           <input
//             type="text"
//             value={mobile}
//             onChange={handleMobileChange}
//             placeholder="Enter mobile number"
//           />
//           {error && <p style={{ color: 'red' }}>Mobile already exists</p>}
//           <button onClick={handleStep1Next}>Next</button>
//         </div>
//       )}

//       {step === 2 && (
//         <div>
//           <h2>Step 2: Email Verification</h2>
//           <input
//             type="text"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter email address"
//           />
//           {error && <p style={{ color: 'red' }}>Email already exists</p>}
//           <button onClick={handleStep2Next}>Next</button>
//         </div>
//       )}

//       {step === 3 && (
//         <div>
//           <h2>Step 3: General Information</h2>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => {setName(e.target.value)}}
//             placeholder="Enter name"
//           />
//           <input
//             type="text"
//             value={lastname}
//             onChange={(e) => setLastname(e.target.value)}
//             placeholder="Enter lastname"
//           />
//           <input
//             type="text"
//             value={graduation}
//             onChange={(e) => setGraduation(e.target.value)}
//             placeholder="Enter graduation"
//           />
//           <button onClick={handleGeneralInfoSubmission}>Submit</button>
//         </div>
//       )}

//       {/* Display User List */}
//       <div>
//         <h2>User List</h2>
//         <ul>
//           {users.map((user, index) => (
//             <li key={index}>
//               {index + 1} email: {user.email} mobile: {user.mobile} Name: {user.name}, Lastname: {user.lastname}, Graduation: {user.graduation}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };


// import React, { useState } from 'react';

// export const Login = () => {
//   const [step, setStep] = useState(1);
//   const [mobile, setMobile] = useState('');
//   const [error, setError] = useState(false);
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [lastname, setLastname] = useState('');
//   const [graduation, setGraduation] = useState('');
//   const [detail1, setDetail1] = useState('');
//   const [detail2, setDetail2] = useState('');
//   const [detail3, setDetail3] = useState('');
//   const [detail4, setDetail4] = useState('');
//   const [detail5, setDetail5] = useState('');

//   const [users, setUsers] = useState([]);

//   const handleMobileVerification = () => {
//     setStep(2);
//   };

//   const handleEmailVerification = () => {
//     setStep(3);
//   };

//   const handleGeneralInfoSubmission = () => {
//     const user = { mobile, email, name, lastname, graduation };
//     setUsers([...users, user]);
//     setStep(4);
//   };

//   const handlePersonalDetailsSubmission = () => {
//     const user = { ...users[users.length - 1], detail1, detail2, detail3, detail4, detail5 };
//     const updatedUsers = users.slice(0, -1);
//     updatedUsers.push(user);
//     setUsers(updatedUsers);
//     localStorage.setItem('usersData', JSON.stringify(updatedUsers));
//     setStep(1);
//     setMobile('');
//     setEmail('');
//     setName('');
//     setLastname('');
//     setGraduation('');
//     setDetail1('');
//     setDetail2('');
//     setDetail3('');
//     setDetail4('');
//     setDetail5('');
//   };

//   const handleStep1Next = () => {
//     const mobileExists = users.some((user) => user.mobile === mobile);
//     setError(mobileExists);

//     if (!mobileExists && mobile.trim() !== '') {
//       handleMobileVerification();
//     } else {
//       // Handle mobile validation error
//     }
//   };

//   const handleStep2Next = () => {
//     const emailExists = users.some((user) => user.email === email);
//     setError(emailExists);

//     if (!emailExists && email.trim() !== '') {
//       handleEmailVerification();
//     } else {
//       // Handle email validation error
//     }
//   };

//   const handleStep3Next = () => {
//     handleGeneralInfoSubmission();
//   };

//   const handleStep4Next = () => {
//     setStep(5);
//   };

//   const handleStep5Next = () => {
//     setStep(6);
//   };

//   const handleStep6Next = () => {
//     setStep(7);
//   };

//   const handleStep7Next = () => {
//     setStep(8);
//   };

//   const handleStep8Next = () => {
//     handlePersonalDetailsSubmission();
//   };
//   const handlechange=(e)=>{
// 	setMobile(e.target.value)
// 	setError(false)
//   }

//   return (
//     <div>
//       {step === 1 && (
//         <div>
//           <h2>Step 1: Mobile Verification</h2>
//           <input
//             type="text"
//             value={mobile}
//             onChange={handlechange}
//             placeholder="Enter mobile number"
//           />
//           {error && <p style={{ color: 'red' }}>Mobile already exists</p>}
//           <button onClick={handleStep1Next}>Next</button>
//         </div>
//       )}

//       {step === 2 && (
//         <div>
//           <h2>Step 2: Email Verification</h2>
//           <input
//             type="text"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter email address"
//           />
//           {error && <p style={{ color: 'red' }}>Email already exists</p>}
//           <button onClick={handleStep2Next}>Next</button>
//         </div>
//       )}

//       {step === 3 && (
//         <div>
//           <h2>Step 3: General Information</h2>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             placeholder="Enter name"
//           />
//           <input
//             type="text"
//             value={lastname}
//             onChange={(e) => setLastname(e.target.value)}
//             placeholder="Enter lastname"
//           />
//           <input
//             type="text"
//             value={graduation}
//             onChange={(e) => setGraduation(e.target.value)}
//             placeholder="Enter graduation"
//           />
//           <button onClick={handleStep3Next}>Next</button>
//         </div>
//       )}

//       {step === 4 && (
//         <div>
//           <h2>Step 4: Step 4 Content</h2>
//           {/* Your Step 4 content here */}
//           <button onClick={handleStep4Next}>Next</button>
//         </div>
//       )}

//       {step === 5 && (
//         <div>
//           <h2>Step 5: Step 5 Content</h2>
//           {/* Your Step 5 content here */}
//           <button onClick={handleStep5Next}>Next</button>
//         </div>
//       )}

//       {step === 6 && (
//         <div>
//           <h2>Step 6: Step 6 Content</h2>
//           {/* Your Step 6 content here */}
//           <button onClick={handleStep6Next}>Next</button>
//         </div>
//       )}

//       {step === 7 && (
//         <div>
//           <h2>Step 7: Step 7 Content</h2>
     
//           <button onClick={handleStep7Next}>Next</button>
//         </div>
//       )}

//       {step === 8 && (
//         <div>
//           <h2>Step 8: Personal Details</h2>
//           <input
//             type="text"
//             value={detail1}
//             onChange={(e) => setDetail1(e.target.value)}
//             placeholder="Enter detail 1"
//           />
//           <input
//             type="text"
//             value={detail2}
//             onChange={(e) => setDetail2(e.target.value)}
//             placeholder="Enter detail 2"
//           />
//           <input
//             type="text"
//             value={detail3}
//             onChange={(e) => setDetail3(e.target.value)}
//             placeholder="Enter detail 3"
//           />
//           <input
//             type="text"
//             value={detail4}
//             onChange={(e) => setDetail4(e.target.value)}
//             placeholder="Enter detail 4"
//           />
//           <input
//             type="text"
//             value={detail5}
//             onChange={(e) => setDetail5(e.target.value)}
//             placeholder="Enter detail 5"
//           />
//           <button onClick={handleStep8Next}>Submit</button>
//         </div>
//       )}

//       {/* Display User List */}
//       <div>
//         <h2>User List</h2>
//         <ul>
//           {users.map((user, index) => (
//             <li key={index}>
//               {index + 1} email: {user.email} mobile: {user.mobile} Name: {user.name}, Lastname: {user.lastname}, Graduation: {user.graduation}
//               {user.detail1 && `, Detail 1: ${user.detail1}`}
//               {user.detail2 && `, Detail 2: ${user.detail2}`}
//               {user.detail3 && `, Detail 3: ${user.detail3}`}
//               {user.detail4 && `, Detail 4: ${user.detail4}`}
//               {user.detail5 && `, Detail 5: ${user.detail5}`}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };


// import React, { useState } from 'react';

// export const Login = () => {
//   const [step, setStep] = useState(1);
//   const [mobile, setMobile] = useState('');
//   const [email, setEmail] = useState('');
//   const [panCard, setPanCard] = useState('');
//   const [dateOfBirth, setDateOfBirth] = useState('');
//   const [job, setJob] = useState('');
//   const [annualIncome, setAnnualIncome] = useState('');
//   const [birthPlace, setBirthPlace] = useState('');
//   const [fatherName, setFatherName] = useState('');
//   const [ifscCode, setIfscCode] = useState('');
//   const [accountNo, setAccountNo] = useState('');
//   const [accountName, setAccountName] = useState('');
//   const [users, setUsers] = useState([]);

//   const handleStep1Next = () => {
//     setStep(2);
//   };

//   const handleStep2Next = () => {
//     setStep(3);
//   };

//   const handleStep3Next = () => {
//     setStep(4);
//   };

//   const handleStep4Next = () => {
//     setStep(5);
//   };

//   const handleStep5Next = () => {
//     const user = {
//       mobile,
//       email,
//       panCard,
//       dateOfBirth,
//       job,
//       annualIncome,
//       birthPlace,
//       fatherName,
//       ifscCode,
//       accountNo,
//       accountName,
//     };
//     setUsers([...users, user]);
//     setStep(1);
//     setMobile('');
//     setEmail('');
//     setPanCard('');
//     setDateOfBirth('');
//     setJob('');
//     setAnnualIncome('');
//     setBirthPlace('');
//     setFatherName('');
//     setIfscCode('');
//     setAccountNo('');
//     setAccountName('');
//   };

//   return (
//     <div>
//       {step === 1 && (
//         <div>
//           <h2>Step 1: Mobile Verification</h2>
//           <input
//             type="text"
//             value={mobile}
//             onChange={(e) => setMobile(e.target.value)}
//             placeholder="Enter mobile number"
//           />
//           <button onClick={handleStep1Next}>Next</button>
//         </div>
//       )}

//       {step === 2 && (
//         <div>
//           <h2>Step 2: Email Verification</h2>
//           <input
//             type="text"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter email address"
//           />
//           <button onClick={handleStep2Next}>Next</button>
//         </div>
//       )}

//       {step === 3 && (
//         <div>
//           <h2>Step 3: Pan Card and Date of Birth</h2>
//           <input
//             type="text"
//             value={panCard}
//             onChange={(e) => setPanCard(e.target.value)}
//             placeholder="Enter Pan Card"
//           />
//           <input
//             type="text"
//             value={dateOfBirth}
//             onChange={(e) => setDateOfBirth(e.target.value)}
//             placeholder="Enter Date of Birth"
//           />
//           <button onClick={handleStep3Next}>Next</button>
//         </div>
//       )}

//       {step === 4 && (
//         <div>
//           <h2>Step 4: Job, Annual Income, Birth Place, Father's Name</h2>
//           <input
//             type="text"
//             value={job}
//             onChange={(e) => setJob(e.target.value)}
//             placeholder="Enter Job"
//           />
//           <input
//             type="text"
//             value={annualIncome}
//             onChange={(e) => setAnnualIncome(e.target.value)}
//             placeholder="Enter Annual Income"
//           />
//           <input
//             type="text"
//             value={birthPlace}
//             onChange={(e) => setBirthPlace(e.target.value)}
//             placeholder="Enter Birth Place"
//           />
//           <input
//             type="text"
//             value={fatherName}
//             onChange={(e) => setFatherName(e.target.value)}
//             placeholder="Enter Father's Name"
//           />
//           <button onClick={handleStep4Next}>Next</button>
//         </div>
//       )}

//       {step === 5 && (
//         <div>
//           <h2>Step 5: Bank Account Details</h2>
//           <input
//             type="text"
//             value={ifscCode}
//             onChange={(e) => setIfscCode(e.target.value)}
//             placeholder="Enter IFSC Code"
//           />
//           <input
//             type="text"
//             value={accountNo}
//             onChange={(e) => setAccountNo(e.target.value)}
//             placeholder="Enter Account Number"
//           />
//           <input
//             type="text"
//             value={accountName}
//             onChange={(e) => setAccountName(e.target.value)}
//             placeholder="Enter Account Name"
//           />
//           <button onClick={handleStep5Next}>Submit</button>
//         </div>
//       )}
// 	   <div>
//         <h2>User List</h2>
//         <ul>
//           {users.map((user, index) => (
//             <li key={index}>
//               {index + 1} Mobile: {user.mobile}, Email: {user.email}, Pan Card: {user.panCard}, Date of Birth: {user.dateOfBirth}, Job: {user.job}, Annual Income: {user.annualIncome}, Birth Place: {user.birthPlace}, Father's Name: {user.fatherName}, IFSC Code: {user.ifscCode}, Account No: {user.accountNo}, Account Name: {user.accountName}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };


