import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { svgAssets } from '../../assets/asset';
import { toggleNavBarAction } from '../../redux/actions/toggleNavBar.action';
import './navbar.css'
import { Input } from 'antd';
import { Modal } from 'antd';
import axios from 'axios';
import information from '../../assets/png/version.png';
export const Navbar = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
	const toggle = useSelector(state => state?.toggle?.isToggle);
	const [value, setVlaue] = useState('');
	const [isModalOpen, setIsmodel] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const dropdownRef = useRef(null);
	const [versionData, setVersionData] = useState({})
	const channel = useSelector(state => state?.kyc?.channel || '');

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleToggle = () => {
		dispatch(toggleNavBarAction(toggle));
	};

	const onclickJourney = () => {
		window.open('https://ekyc.bigul.co/track-journey', '_blank');

	}

	useEffect(() => {
		const fetchDataAndUpdateVersion = async () => {
			try {
				const response = await axios.get('https://bcms.bigul.co/api/get-dkyc-version');
				if (response.data.status) {
					setVersionData(response?.data?.data)
					const apiVersion = response?.data?.data.version;
					const localVersion = localStorage.getItem('version');
					if (apiVersion != localVersion) {
						setIsmodel(true);
						localStorage.setItem('version', apiVersion)
					}
					else {
						// setIsmodel(false)
					}
				}

			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchDataAndUpdateVersion();
		const intervalId = setInterval(fetchDataAndUpdateVersion, 3600000);
		return () => clearInterval(intervalId);
	}, []);

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			navigate('/application', { state: { value: value } })
		}
	};
	const handleOnchange = (e) => {
		if (e.target.value.length == 0) {
			navigate('/',)
		}
		setVlaue(e.target.value.toUpperCase());
	};

	const formatDate = (dateString) => {
		const options = {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			hour12: true
		};
		const date = new Date(dateString);
		const formattedDate = date.toLocaleDateString('en-GB', options);
		return `${formattedDate}`
	};
	const handleRefresh = () => {
		window.location.reload()
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<>
			<div className="w-full main-nav pt-5 pb-5 version-modal-design flex items-center z-30 fixed bg-[#F6F8F9]">
				<Modal
					// title="New Updates!"
					className="w-full p-[33px] cc-modal-title"
					fontSize="22px"
					width={'560px'}
					// maxWidth={'600px'}
					style={{ textAlign: 'center', fontSize: '22px' }}
					open={isModalOpen}
					centered={true}
					// onCancel={handleCancel}
					closable={false}
					footer={null}
				>
					<>
						<h3 style={{ textAlign: 'left', fontSize: '24px', fontWeight: '500', }}>Update</h3>
						<div className="py-4" style={{ textAlign: 'left', fontSize: '20px' }} dangerouslySetInnerHTML={{ __html: versionData?.description }}></div>
						<div style={{
							display: 'flex',
							background: '#ddd',
							padding: '15px 25px',
							borderRadius: '4px',
							justifyContent: 'space-between',
							fontSize: '20px',
							fontWeight: '500',
							margin: "20px 0 40px"
						}}>
							<p style={{ color: '#666' }}>Last Updated</p>
							<p style={{ color: '#444', fontWeight: '600' }}>{formatDate(versionData?.updated_at)} </p>
						</div>
						<button className='btn btn-primary' style={{ padding: '12px 30px', borderRadius: '6px', border: '1px solid #ccc', background: '#222', color: '#fff', fontSize: '16px', marginBottom: '20px' }} onClick={handleRefresh}>Refresh</button>

					</>
				</Modal>
				<div className=" pl-5 md:pl-10 cursor-pointer absolute w-[120px] md:w-auto ">
					<Link to="/">
						{
							((location.pathname.includes('kyc1') && (channel == 'Bonanza'))) ?
								<img alt="app_logo" className='h-auto' src={svgAssets.Bonanza} style={{ height: '100px', width: '150px' }} />
								:
								<img alt="app_logo" className='h-auto' src={svgAssets.bigul} style={{ height: '100px', width: '150px' }} />

						}
					</Link>
				</div>
				<div className="pl-5 pr-2.5 lg:ml-[300px] md:mr-5 flex grow items-center justify-between">
					<div className='grow lg:hidden'></div>
					<svg className="h-5 w-5 fill-current lg:hidden" viewBox="0 0 20 20">
						<path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" fill='black' />
					</svg>
					<div className="h-[45px] px-3.5 flex items-center justify-between cursor-pointer rounded-[10px] bg-white">
						<Input
							className='w-full pr-5 m-0 text-xs inline-flex items-center outline-none border-none font-poppinsRegular'
							placeholder='Enter name ,pan ,Mobile number'
							onKeyDown={handleKeyDown}
							onChange={handleOnchange}
							value={value} />
					</div>
					<div onClick={() => { setIsmodel(true) }} className="h-[45px] w-[200px] px-3.5 flex items-center justify-between cursor-pointer rounded-[10px] bg-white">
						<span className="text-[#868686] leading-6 font-medium font-poppinsMedium">{'Version :' + ' ' + versionData.version}</span>
						<img className="w-[30px] h-[30px] pt-1 " alt="user_img" src={information} />
					</div>
					{/* {
						location?.pathname == '/' &&
						<div style={{
							background: '#121212',
							height: '40px',
							width: '150px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							alignSelf: 'center',
							borderRadius: 10,
							cursor: 'pointer',
						}} onClick={onclickJourney}>
							<p style={{ color: 'white', fontWeight: 'bold' }}>Track Journey</p>
						</div>
					} */}
					<dv></dv>
					<div className="flex items-center ">
						<div>
						</div>
						<div ref={dropdownRef} className="relative">
							<div onClick={toggleDropdown} className="h-[45px]  px-3.5 flex items-center cursor-pointer rounded-[10px]">
								<span className="text-[#868686] leading-6 font-medium font-poppinsMedium">{userDetail?.role_code == "VRF" ? 'Verifier' : 'RM'} : {userDetail?.username}</span>
								<img className="w-[40px] h-[40px] pt-1 ml-2 " alt="user_img" src={svgAssets.navbar.defaultUser} />

							</div>
							{dropdownOpen && (
								<ul className="dropdown-menu show absolute shadow-xl rounded-lg right-0 bg-white h-auto w-[160px] p-4 mt-4" role="menu">
									<li>
										<h6 className="dropdown-header text-center pb-2 ">{userDetail?.fname}</h6>
									</li>
									<li className='border-2 mb-2'></li>
									<li className='flex p-1'>
										<img className="mr-2.5" alt="dashboard" src={svgAssets.sidebar.dashboard} />
										<Link className="dropdown-item" to="/">Dashboard</Link>
									</li>
									{userDetail?.username !== "DIRECTCLIENT" && (
										<li className='flex pt-1 pb-1'>
											<img className="mr-2.5" alt="profile" src={svgAssets.sidebar.accountList} />
											<Link className="dropdown-item" to="/update">Profile</Link>
										</li>
									)}
									<li className='flex p-1'>
										<img className="mr-2.5" alt="logout" src={svgAssets.sidebar.logout} />
										<Link className="dropdown-item" to="/login">Logout</Link>
									</li>
								</ul>
							)}
						</div>
						<img className={`mx-2.5 cursor-pointer lg:hidden ${toggle ? 'hamburger-overlay' : ''}`} alt="hamburger" src={svgAssets.navbar.hamburger} onClick={() => handleToggle()} />
						<div className={`lg:hidden ${toggle ? 'hamburger-overlay' : 'hidden'}`} onClick={() => handleToggle()}></div>
					</div>
				</div>
			</div>
		</>
	);
};
