

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import { toggleNavBarAction } from '../../redux/actions/toggleNavBar.action';
import { clearKycAction, setChannelAction } from '../../redux/actions/kyc/kyc.action';
import { clearVerifierAction } from '../../redux/actions/kyc/verify.action';
import { InputBtnIcon } from '../../components/inputs/InputBtnIcon';
import { getRole } from '../../utils/getRole';
import { sidebarData } from './sidebarData';
import { sidebarData1 } from './sidebarData';
import { changeStep1StatusAction } from '../../redux/actions/kyc/step1.action';
import axios from 'axios';
import { startLoaderAction, stopLoaderAction } from '../../redux/actions/app.action';
import { toast } from 'react-toastify';
import { store } from '../../redux/store';

export const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [isModalOpen, setModalOpen] = useState(false);
    const userDetail = useSelector(state => state?.user?.userDetails?.[0] || {});
    const toggle = useSelector(state => state?.toggle?.isToggle);
    const [active, setActive] = useState({
        route: '/',
        nestedRoute: '',
        isDropDownActive: false
    });


    const handleRoute = async ({ route, nestedRoute, name }) => {
        if (nestedRoute) {
            navigate(`${route}${nestedRoute}`);
            dispatch(toggleNavBarAction(false));
            if (nestedRoute === '/all') {
                dispatch(clearVerifierAction());
                dispatch(toggleNavBarAction(false));
            }
        } else {
            if (name === 'Link Aadhar') {
                window.open('https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar', '_blank');
            }
            if (name === 'Support') {
                const encodedFullName = btoa(userDetail?.username);
                const url = `https://kycsupport.bigul.co/directAuth/ae40247720f20be0985c9555228dc5aeb0ca6e3b31191b0a434448ef8bc5b007/${userDetail?.user_id}/${encodedFullName}`;
                window.open(url, '_blank');
            }
            if (name === 'Rm Buyback') {
                window.open('https://bigul.co/buy-back-rm-login', '_blank');
            }
            if (name === 'PreTrade Confirmation') {
                store.dispatch(startLoaderAction());
                try {
                    const response = await axios.post('https://bigul.co/api/encPaymentUrl', {
                        enc_data: `rmname=${userDetail?.FullName}&rmcode=${userDetail?.username}&rmbranched=${userDetail?.BranchCode}`
                    });
                    if (response?.data?.enc_data) {
                        store.dispatch(stopLoaderAction());
                        window.open('https://bonanza.bigul.app/ptcc-new?enc=' + response?.data?.enc_data, '_blank');
                    }
                    else {
                        store.dispatch(stopLoaderAction());

                        toast('something went wrong')
                    }


                } catch (error) {
                    store.dispatch(stopLoaderAction());

                    toast('something went wrong')
                }

            }
            else {
                navigate(route);
                dispatch(toggleNavBarAction(false));
                if (route === '/kyc' || route === '/kyc1') {
                    dispatch(clearKycAction());
                    dispatch(toggleNavBarAction(false));
                    if (route === '/kyc') {
                        dispatch(changeStep1StatusAction({ type1: 'emailOtp', value1: 'pending', type2: 'emailInput', value2: 'pending' }));
                        dispatch(setChannelAction({ channel: 'Bigul' }));
                    } else if (route === '/kyc1') {
                        dispatch(changeStep1StatusAction({ type1: 'emailOtp', value1: 'pending', type2: 'emailInput', value2: 'pending' }));
                        dispatch(setChannelAction({ channel: 'Bonanza' }));
                    }
                }
            }
        }
    };

    const handleModal = () => {
        setModalOpen(true);
    };

    useEffect(() => {
        const splitPath = location.pathname.split('/');
        const route = splitPath[1] !== '' ? `/${splitPath[1]}` : '/';
        const nestedRoute = splitPath[2] && splitPath[2] !== '' ? `/${splitPath[2]}` : null;

        setActive({
            route,
            nestedRoute,
            isDropDownActive: !!nestedRoute
        });
    }, [location.pathname]);

    const handleSubmit = () => {
        setModalOpen(false);
        navigate('/login');
    };

    const [isToggled, setIsToggled] = useState(false);
    const handleButtonClick = () => {
        setIsToggled(!isToggled);
    };

    const encodeToBase64 = (str) => {
        return btoa(unescape(encodeURIComponent(str)));
    };
    
    const renderSidebarItems = (dataList) => {

        // const excludedUsernames = ['Q56', 'B100332', 'Q5A', 'B101207', 'B101069', 'B100591', 'O5V', 'O5D']
        return dataList.map((data, index) => {
            const loginType = getRole(userDetail?.role_name || '');
            const isValidLogin = data.roles.find(el => el === loginType);

            // Check for "MF Partner Onboarding" and usertype null
            if (data.name === 'BA onboarding' && userDetail.usertype != "RM") {
                return null; // Do not render this item if usertype is null
            }
            if (data.name === 'Start Bigul KYC' ) {
                return null; // Do not render this item if the username is in the excludedUsernames array
            }
            if (data.name === 'Start Bonanza KYC') {
                return null; // Do not render this item if the username is in the excludedUsernames array
            }


            if (isValidLogin) {
                return (
                    <li key={`sidebar_${index}`} className="flex flex-col">
                        <div
                            className={`py-2.5 mb-2.5 flex items-center justify-between cursor-pointer rounded-[10px] transition-colors duration-300
                                ${active.route === data.route ? 'bg-black text-white px-[11px]' : 'text-[#808080] hover:bg-black px-3'}
                            `}
                            onClick={
                                ((data.name === 'Start Bigul KYC' || data.name === 'Start Bonanza KYC') && (userDetail.role_id == 3))
                               
                                    ? () =>
                                        {
                                            const encodedUsername = encodeToBase64(userDetail.username);
                                            window.location.href = `https://kyc.bigul.co/user/directLogin/${encodedUsername}`;
                                        }
                                        
                                        //  window.location.href = 'https://kyc.bigul.co/user/directLogin/'+atob(userDetail.username)
                                    :
                                    data.isPopup ?
                                        handleModal
                                        :
                                        () => handleRoute({
                                            route: data.route,
                                            name: data.name,
                                            nestedRoute: data?.nestedRoutes && (active.route !== data.route || !active.isDropDownActive)
                                                ? data.nestedRoutes[0].route
                                                : null
                                        })
                            }
                        >
                            <div className="flex items-center justify-between">
                                <img
                                    className="mr-2.5"
                                    alt="kyc_image"
                                    src={active.route === data.route ? data.selectedIcon : data.icon}
                                />
                                <span>{data.name}</span>
                            </div>
                            {
                                data?.nestedRoutes && (
                                    <svg
                                        className={`h-6 w-6 transform duration-150 ease-in-out
                                            ${active.route === data.route && active.isDropDownActive
                                                ? 'fill-white rotate-180'
                                                : active.route === data.route
                                                    ? 'fill-white'
                                                    : 'fill-[#808080]'
                                            }
                                        `}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        />
                                    </svg>
                                )
                            }
                        </div>
                        {
                            active.route === data.route && active.isDropDownActive && data.nestedRoutes
                                ? (
                                    <div className="px-3 mb-2.5 flex justify-center">
                                        <ul>
                                            {
                                                data.nestedRoutes.map((nestedData, nestedIndex) => {
                                                    const nestedLoginType = getRole(userDetail?.role_name || '');
                                                    const nestedIsValidLogin = nestedData?.roles.find(el => el === nestedLoginType);

                                                    if (nestedIsValidLogin) {
                                                        return (
                                                            <li
                                                                key={`sidebar_nested_route_${nestedIndex}`}
                                                                className={`mb-2.5 py-1 cursor-pointer duration-300
                                                                    ${active.nestedRoute === nestedData.route ? 'text-[#5367FC]' : 'text-[#808080] hover:text-[#5367FC]'}
                                                                `}
                                                                onClick={() => handleRoute({
                                                                    route: data.route,
                                                                    nestedRoute: nestedData.route
                                                                })}
                                                            >
                                                                {nestedData.name}
                                                            </li>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                                : null
                        }
                    </li>
                );
            }
            return null;
        });
    };

    return (
        <>
            <div
                className={`w-[300px] h-full lg:h-[calc(100vh-105px)] mt-[0px] lg:mt-[105px] pb-5 fixed leading-6 font-medium 
                    font-poppinsMedium transition duration-300 ease-in-out lg:left-[0px] sidebar-menu-list  bg-[#F6F8F9] pt-4 z-[111] md:z-50
                    ${toggle ? 'left-[0px]' : '-left-[300px]'} ${isToggled ? 'active' : ''}`
                }
            >
                <ul className="w-full h-full px-[30px] grow overflow-y-auto scroll sidebar-menu-list">
                    {
                        userDetail.username === 'DIRECTCLIENT'
                            ? renderSidebarItems(sidebarData1)
                            : renderSidebarItems(sidebarData)
                    }
                </ul>
                <div className="sidebar-toggle-btn" onClick={handleButtonClick}>
                    <svg className="h-6 w-6 transform duration-150 ease-in-out fill-[#ffffff]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </div>
            </div>
            <Modal
                title="Are you sure, you want to logout?"
                open={isModalOpen}
                onCancel={() => setModalOpen(false)}
                closable={true}
                centered={true}
                width={500}
                footer={null}
                className="w-full px-[33px] text-center z-[111]"
            >
                <div className="flex justify-center items-center gap-x-5 px-[10px]">
                    <div>
                        <InputBtnIcon
                            btnWidth="120px"
                            inputWidth="0px"
                            btnText="Logout"
                            inputType="resendMobileOtpInput"
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};
